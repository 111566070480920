
import './topBackground.scss';

/**
 * 位于顶部的背景组件
 */
export default function TopBackground(props){
    return (
        <div className="bmf-top-background" >
            <ul className="background-bubbles" >
                <li />
                <li />
                <li />
                <li />
                <li />{/* 5 */}
                <li />
                <li />
                <li />
                <li />
                <li />{/* 10 */}
                <li />
                <li />
                <li />
            </ul>
        </div>
    );
};