import { Menu } from 'antd';
import { withRouter } from 'react-router';
import React, { useRef, useState } from 'react';
import utils from "../module/utils";
import { HomeOutlined, MailOutlined, BookOutlined, SearchOutlined, PieChartOutlined, ThunderboltOutlined, LinkOutlined, FileSearchOutlined, CloudDownloadOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';
import "./headerNav.scss";

function HeaderNav(props){

    const hashPath = props.location.pathname;

    const headerRef = useRef();

    const [currentPath, updatePath] = useState(hashPath);

    const [isTop, updateIsTop] = useState(true); // 判断页面是否向下滚动了

    const isHome = currentPath === '/Home' ? true: false;

    React.useEffect(() => {
        updatePath(hashPath);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    },[hashPath]);

    function onScroll(){
        let topPS = headerRef.current.offsetTop - document.documentElement.scrollTop;
        if(topPS < 0){
            updateIsTop(false);
        } else {
            updateIsTop(true);
        }
    };

    const menuItemList = [
        {
            text: "Home",
            icon: <HomeOutlined style={{fontSize: "20px", marginRight: "5px"}} />,
            path: "#/Home"
        },
        {
            text: "Webserver",
            icon: <PieChartOutlined style={{fontSize: "20px", marginRight: "5px"}} />,
            path: "#/WebServer",
            subMenu: [
                {
                    text: "Signature discovery",
                    path: "#/WebServer/Analysis"
                },
                {
                    text: <span>Response prediction by<br/>TIRSF signatures</span>,
                    path: "#/WebServer/Application"
                },
                {
                    text: "Response prediction by existing signatures",
                    path: "#/WebServer/Signatures"
                }
            ]
        },
        {
            text: "Help",
            icon: <BookOutlined style={{fontSize: "20px", marginRight: "5px"}} />,
            path: "#/Help"
        },
        /* {
            text: "Download",
            icon: <DownloadOutlined style={{fontSize: "20px", marginRight: "5px"}} />,
            path: "#/Download"
        }, */
        {
            text: "Citation",
            icon: <LinkOutlined style={{fontSize: "20px", marginRight: "5px"}} />,
            path: "#/Citation"
        },
        {
            text: "Contact",
            icon: <LinkOutlined style={{fontSize: "20px", marginRight: "5px"}} />,
            path: "#/Contact"
        }
    ];

    //点击菜单的回调
    function onClickMenu(props){
        const { item, key, keyPath, domEvent } = props;//antd提供的点击菜单回调传入参数
        //console.log(props)
        utils.routeToPage(key);//在下面的render里已经将path传给了key，所以这里取key就获得了上面配置的path
    };

    //点击菜单的回调
    function onClickMenuNew(path){
        utils.routeToPage(path);
    };

    return (
        <React.Fragment>
            <div className="headernav-ref" ref={headerRef} />
            <div style={{height: "64px"}} />
            <div className={"headernav-wrap " + ((!isHome && isTop) ? "" : "bmf-theme-bgcolor")} >
                {/* <div className='headernav-logo'>RPS</div>
                <Menu onClick={onClickMenu} theme='dark' mode='horizontal' style={{ whiteSpace: "nowrap", fontSize: "20px" }} >
                    {menuItemList.map((item, index) => {
                        return (
                            <Menu.Item key={item.path} >
                                {item.icon}{item.text}
                            </Menu.Item>
                        );
                    })}
                </Menu> */}
                <div className='headernav-logo'>TIRSF</div>
                {menuItemList.map((item, index) => {
                    return (
                        <div className={"headernav-item-wrap" + ((!isHome && isTop) ? "" : " bmf-theme-bgcolor") + (currentPath.indexOf(item.path.split('#')[1]) > -1 ? " activated bmf-theme-bdcolor-sc bmf-theme-color-sc" : "") + (item.subMenu ? " has-sub" : "")} onClick={item.subMenu ? null : () => onClickMenuNew(item.path)} key={index} >
                            {/* item.icon */}<span>{item.text}</span>
                            {item.subMenu && 
                            <React.Fragment>
                                <span className="headernav-item-sub-icon" >
                                    <DownOutlined style={{fontSize: "12px", marginLeft: "10px"}} />
                                </span>
                                <div className="headernav-item-sub-menu bmf-theme-color-sc" >
                                    {item.subMenu.map((item2, index2) => {
                                        return (
                                            <div className="headernav-item-sub-menu-item" key={index2} onClick={() => onClickMenuNew(item2.path)} >
                                                {item2.text}
                                            </div>
                                        )
                                    })}
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    );
                })}
                <div className={"headernav-item-wrap" + ((!isHome && isTop) ? "" : " bmf-theme-bgcolor")} onClick={() => window.open("https://renlab.org/")} key={999} >
                    {/* item.icon */}<span>{"Renlab"}</span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(HeaderNav);