import FileSaver from "file-saver";

/**
 * 通用方法utils
 * @namespace utils
 */
const utils = {
    /**
     * 站内页面跳转，使用hash路由
     * @param {String} path 目标页面路径，例："#/WebServer"
     */
    routeToPage: function(path){
        window.location.href = path;
    },

    /**
     * 查找目标字符串是否在某个字符串数组中
     * @param {String[]} arr 
     * @param {String} target 
     */
    contain: function(arr,target){
        if(!arr){
            return false;
        }

        return arr.indexOf(target) > -1;
    },

    /**
     * 为指定数组的每一项加上"key"字段，用于处理传给table的data数组
     * @param {Object[]} arr 要加上key字段的数组
     * @return 加上key字段后的原数组的复制
     */
    addKeyForArray: function(arr){
        if(!arr){
            return [];
        }
        let _arr = [];
        arr.forEach((item, index) => {
            item.key = index;
            _arr.push(item);
        });
        return _arr;
    },

    /**
     * 判断当前页面是否位于home页，用于区别处理home页的样式
     */
    isHome: function(){
        return window.location.hash === '#/Home';
    },

    /**
     * 将指定文字生成指定文件名的文件并下载
     * @param {String} text 用于生成文件的文本内容
     * @param {String} fileName 生成的文件名，如"test.fasta"
     */
    downloadText: function(text, fileName){
        let blob = new Blob([text], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, fileName);
    },

    /**
     * 重定向页面至Http
     */
    redirectToHttp: function(){
        let currentProtocol = window.location.protocol;
        let currentHref = window.location.href;
        if(currentProtocol === 'https:'){
            let newHref = currentHref.replace('https://', 'http://');
            window.location.href = newHref;
        }
    },
    /**
     * 给数字加上千位分割符
     * @param {} num 
     */
    toThousands: function(num) {
        num = (num || 0).toString();
        let result = '';
        while (num.length > 3) {
            result = ',' + num.slice(-3) + result;
            num = num.slice(0, num.length - 3);
        }
        if (num) {
            result = num + result;
        }
        return result;
    },
    /**
     * 滚动到指定id的Dom元素
     * @param {String} id 需要滚动到的元素的Id
     */
    scrollToElementById: function (id) {
        let targetDom = document.getElementById(id);
        if(targetDom){
            targetDom.scrollIntoView({
                block: "center",
                behavior: "smooth"
            });
        }
    },
    /**
     * 将输入的数字转化为1st,2nd,3rd,4th...，注意目前4以后的全部加后缀th，以后有必要再修正
     * @param {Number} num 要转换为序号的数字
     */
    toOrderNumber: function (num) {
        if(!num || num < 0){
            return 'Out of range';
        }

        const orderMap = {
            1: "1st",
            2: "2nd",
            3: "3rd"
        };

        if (num < 4) {
            return orderMap[num];
        } else {
            return num + "th";
        }
    },
    /**
     * 将传入的数组（元素为对象）根据某个属性值进行顺序排列的方法，注意会直接改变原数组，同时本方法返回数组的引用
     * @param {Array} array 
     * @param {String} property 
     * @param {Boolean} reverse 传入该参数为true，则代表倒序排列
     */
    sortArrayByProp: function (array, property, reverse) {

        function compare (property) {
            return function(a, b){
                if (reverse) {
                    return b[property] - a[property]
                } else {
                    return a[property] - b[property]
                }
            }
        }

        array.sort(compare(property));
        return array;
    },
    /**
     * 将时间戳转换为本地时间字符串
     * @param {Number} timestamp 
     */
    timestampToLocalTime: function (timestamp) {

        let date = new Date(parseInt(timestamp));

        return this.dateFormat('YYYY-mm-dd HH:MM:SS', date);
    },
    /**
     * 日期格式化
     * @param {String} fmt 
     * @param {Date} date 
     * @returns 
     */
    dateFormat: function (fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    },
    /**
     * 输入文件大小，返回计算表示文件大小多少MB的字符串
     * @param {Number} fileSize 
     * @returns 
     */
    renderFileSizeMB: function (fileSize) {
        return ((fileSize/1024/1024).toFixed(2) + " MB");
    },
    /**
     * 输入文件大小，返回计算表示文件大小多少KB的字符串
     * @param {Number} fileSize 
     * @returns 
     */
     renderFileSizeKB: function (fileSize) {
        return ((fileSize/1024).toFixed(2) + " KB");
    },
    /**
     * 判断对象是否是一个数字
     * @param {Obj} obj 
     * @returns 
     */
    isNumber: function (obj) {
        return typeof obj === 'number' && !isNaN(obj);
    },
    /**
     * 数组去重，注意这是最简单的去重法，无法去除重复空对象
     * @param {Array} arr 
     * @returns 
     */
    uniqueArray: function (arr) {
        return Array.from(new Set(arr));
    }
};

export default utils;