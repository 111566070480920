import React, { Suspense, lazy, useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import { createHashHistory } from 'history';
import { Layout, BackTop } from 'antd';
import HeaderNav from './HeaderNav';
import Loading from '../pages/components/Loading';
import utils from '../module/utils';
import TopBackground from '../pages/components/TopBackground/TopBackground';

import './footer.scss';
import REST from '../module/RESTApi';

const { Header, Content, Footer } = Layout;

const Home = lazy(() => import("../pages/Home/Home"));
const Webserver = lazy(() => import("../pages/WebServer/WebServer"));
const AnalysisResult = lazy(() => import("../pages/AnalysisResult/AnalysisResult"));
const PredictionResult = lazy(() => import("../pages/PredictionResult/PredictionResult"));
const CalculationResult = lazy(() => import("../pages/CalculationResult/CalculationResult"));
const Download = lazy(() => import("../pages/Download/Download"));
const Citation = lazy(() => import("../pages/Citation/Citation"));
const Contact = lazy(() => import("../pages/Contact/Contact"));
const Help = lazy(() => import("../pages/Help/Help"));

export default function BiomarkerRouter(){

    const history = createHashHistory();

    const [isHome, updateIsHome] = useState(true);

    function setUserIdCookie(){

        if(window.localStorage.getItem("userId")){ // 如果当前已经储存有userId，则不再请求新的
            return;
        }

        let url = `/login/userid`;
        let params = {};
        REST.post(url, params).then(res => {
            if (res.code == 200) {
                let userId = res.data;
                window.localStorage.setItem("userId", userId);
            }
        });
    };

    React.useEffect(() => {
        utils.redirectToHttp();
        setUserIdCookie(); // 调用设置UserId的cookie的接口
        updateIsHome(utils.isHome()); // 加载页面时先执行一次判断是否在home页
        const unlisten = history.listen((location, action) => { // 监听路由
            updateIsHome(utils.isHome()); //路由改变时执行判断是否在首页
            window.scrollTo(0,0); // 路由改变时先滚动至最上方
        });
        return (() => {
            unlisten();
        });
    },[]);

    return (
        <React.Fragment>

            <Layout style={{backgroundColor: "#fff"}} >
                {!isHome && <TopBackground />}
                <Suspense fallback={<Loading />} >
                    <Router history={history} >
                        <HeaderNav />
                        <Content style={isHome ? {} : {width: "95vw", margin: "auto", maxWidth: "1700px", minHeight: "90vh", paddingBottom: "50px"}} >
                            <BackTop />
                            <Switch>
                                <Route exact={true} path="/Home" component={Home} />
                                <Route path="/WebServer/:type" component={Webserver} />
                                <Route path="/AnalysisResult/:jobId" component={AnalysisResult} />
                                <Route path="/PredictionResult/:jobId" component={PredictionResult} />
                                <Route path="/CalculationResult/:jobId" component={CalculationResult} />
                                <Route path="/Download" component={Download} />
                                <Route path="/Citation" component={Citation} />
                                <Route path="/Contact" component={Contact} />
                                <Route path="/Help" component={Help} />
                                <Redirect to="/Home" />
                            </Switch>
                        </Content>
                        <Footer style={{display: /* isHome */false ? "none" : "block"}} className="bmf-footer" >TIRSF © 2021 The Ren Lab. All Rights Reserved</Footer>
                    </Router>
                </Suspense>
            </Layout>

        </React.Fragment>
    );
}