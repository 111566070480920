import Index from "./router/router";
import './App.less';
import './init.css';

function App() {
  return (
    <div className="App">
      <Index />
    </div>
  );
}

export default App;
